// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Home from './components/Home';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';

const AppContainer = styled.div`
  font-family: 'Inter', sans-serif;
  color: #333;
  background-color: #f8f9fa;
`;

const Header = styled.header`
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem 5%;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled.h1`
  margin: 0;
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(45deg, #1a73e8, #34a853);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #1a73e8;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: #1a73e8;
    &:after {
      visibility: visible;
      transform: scaleX(1);
    }
  }
`;

const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }

  span {
    height: 2px;
    width: 25px;
    background: #333;
    margin-bottom: 4px;
    border-radius: 5px;
  }
`;

const FloatingButton = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
  }
`;

function App() {
  return (
    <Router>
      <AppContainer>
        <Helmet>
          <title>Deeplink - Simplify Your URLs Instantly</title>
          <meta
            name="description"
            content="Transform long URLs into short, memorable links with Deeplink. Boost your click-through rates and track your link performance easily."
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Header>
          <Nav>
            <Logo>Deeplink</Logo>
            <NavLinks>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/features">Features</NavLink>
              <NavLink to="/pricing">Pricing</NavLink>
              <NavLink as="a" href={`${process.env.REACT_APP_FE_URL}`}>
                {" "}
                Login
              </NavLink>
            </NavLinks>
            <HamburgerMenu>
              <span></span>
              <span></span>
              <span></span>
            </HamburgerMenu>
          </Nav>
        </Header>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>

        <Testimonials />

        <FloatingButton href={`${process.env.REACT_APP_FE_URL}`}>
          +
        </FloatingButton>
      </AppContainer>
    </Router>
  );
}

export default App;