// Home.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const HomeContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const HeroSection = styled.section`
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: white;
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
`;

const HeroContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
`;

const Title = styled(motion.h1)`
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background: linear-gradient(to right, #ffffff, #f0f0f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  max-width: 600px;
  margin-bottom: 3rem;
  line-height: 1.5;
`;

const CTAButton = styled(motion.a)`
  display: inline-block;
  background-color: white;
  color: #e73c7e;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0;
  }
`;

const FloatingElement = styled(motion.div)`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
`;

const FloatingCircle1 = styled(FloatingElement)`
  width: 150px;
  height: 150px;
  top: -75px;
  right: 10%;
`;

const FloatingCircle2 = styled(FloatingElement)`
  width: 100px;
  height: 100px;
  bottom: -50px;
  left: 5%;
`;

const FloatingCircle3 = styled(FloatingElement)`
  width: 75px;
  height: 75px;
  top: 40%;
  left: 15%;
`;

const FeaturesSection = styled.section`
  padding: 8rem 0;
  background-color: #f8f9fa;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureItem = styled(motion.div)`
  text-align: center;
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1.5rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #1a73e8;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  color: #4a4a4a;
  line-height: 1.6;
`;

const StatsSection = styled.section`
  background-color: white;
  padding: 6rem 0;
`;

const StatsGrid = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StatItem = styled(motion.div)`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 3rem;
  font-weight: 800;
  color: #1a73e8;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.div`
  font-size: 1.2rem;
  color: #4a4a4a;
`;

function Home() {
    return (
      <HomeContainer>
        <Helmet>
          <title>
            Deeplink - Enterprise-Grade URL Shortening and Link Management
          </title>
          <meta
            name="description"
            content="Deeplink provides lightning-fast URL shortening, powerful analytics, and seamless integration for businesses of all sizes. Optimize your link management today."
          />
        </Helmet>
        <HeroSection>
          <HeroContent>
            <Title
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Elevate Your Link Management
            </Title>
            <Subtitle
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Experience enterprise-grade URL shortening with real-time
              analytics and seamless integration.
            </Subtitle>
            <CTAButton
              href={process.env.REACT_APP_FE_URL}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Start Optimizing Now
            </CTAButton>
          </HeroContent>
          <FloatingCircle1
            animate={{
              y: [0, -20, 0],
              rotate: [0, 360],
            }}
            transition={{
              y: {
                repeat: Infinity,
                duration: 5,
                ease: "easeInOut",
              },
              rotate: {
                repeat: Infinity,
                duration: 20,
                ease: "linear",
              },
            }}
          />
          <FloatingCircle2
            animate={{
              x: [0, 20, 0],
              rotate: [0, -360],
            }}
            transition={{
              x: {
                repeat: Infinity,
                duration: 7,
                ease: "easeInOut",
              },
              rotate: {
                repeat: Infinity,
                duration: 25,
                ease: "linear",
              },
            }}
          />
          <FloatingCircle3
            animate={{
              y: [0, 30, 0],
              x: [0, -20, 0],
              rotate: [0, 360],
            }}
            transition={{
              y: {
                repeat: Infinity,
                duration: 6,
                ease: "easeInOut",
              },
              x: {
                repeat: Infinity,
                duration: 6,
                ease: "easeInOut",
              },
              rotate: {
                repeat: Infinity,
                duration: 30,
                ease: "linear",
              },
            }}
          />
        </HeroSection>

        <FeaturesSection>
          <FeatureGrid>
            {[
              {
                icon: "⚡️",
                title: "Lightning-Fast Shortening",
                description:
                  "Generate short links in milliseconds with our optimized algorithms.",
              },
              {
                icon: "📊",
                title: "Comprehensive Analytics",
                description:
                  "Gain deep insights with our real-time, detailed link performance metrics.",
              },
              {
                icon: "🔗",
                title: "Custom Branded Links",
                description:
                  "Create memorable, on-brand short links that resonate with your audience.",
              },
              {
                icon: "🔌",
                title: "Seamless Integration",
                description:
                  "Easily integrate with your existing tools through our robust API.",
              },
              {
                icon: "🔒",
                title: "Enterprise-Grade Security",
                description:
                  "Rest easy with our top-tier security measures and compliance.",
              },
              {
                icon: "🌐",
                title: "Global CDN",
                description:
                  "Ensure fast redirection worldwide with our distributed network.",
              },
            ].map((feature, index) => (
              <FeatureItem
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <FeatureIcon>{feature.icon}</FeatureIcon>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureItem>
            ))}
          </FeatureGrid>
        </FeaturesSection>

        <StatsSection>
          <StatsGrid>
            {[
              { number: "1B+", label: "Links Shortened" },
              { number: "150M+", label: "Monthly Clicks" },
              { number: "99.99%", label: "Uptime" },
            ].map((stat, index) => (
              <StatItem
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <StatNumber>{stat.number}</StatNumber>
                <StatLabel>{stat.label}</StatLabel>
              </StatItem>
            ))}
          </StatsGrid>
        </StatsSection>
      </HomeContainer>
    );
}

export default Home;