// Testimonials.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TestimonialSection = styled.section`
  background-color: #f8f9fa;
  padding: 4rem 0;
`;

const TestimonialContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #1a73e8;
  text-align: center;
  margin-bottom: 2rem;
`;

const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const TestimonialCard = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const QuoteText = styled.p`
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 1rem;
  font-style: italic;
`;

const Author = styled.p`
  font-weight: bold;
  color: #1a73e8;
`;

const testimonials = [
    {
        quote: "Deeplink has revolutionized our marketing campaigns. The analytics are incredibly insightful!",
        author: "Jane Doe, Marketing Director"
    },
    {
        quote: "The API integration was seamless. It's now an integral part of our workflow.",
        author: "John Smith, CTO"
    },
    {
        quote: "Customer support is top-notch. They're always there when we need them.",
        author: "Alice Johnson, Social Media Manager"
    }
];

function Testimonials() {
    return (
        <TestimonialSection>
            <TestimonialContainer>
                <Title>What Our Customers Say</Title>
                <TestimonialGrid>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                            <QuoteText>"{testimonial.quote}"</QuoteText>
                            <Author>{testimonial.author}</Author>
                        </TestimonialCard>
                    ))}
                </TestimonialGrid>
            </TestimonialContainer>
        </TestimonialSection>
    );
}

export default Testimonials;