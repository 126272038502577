// Pricing.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const PricingContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #1a73e8;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #5f6368;
  max-width: 600px;
  margin: 0 auto;
`;

const PricingTable = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
`;

const PricingPlan = styled(motion.div)`
  background: white;
  border-radius: 10px;
  padding: 2rem;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const PlanName = styled.h3`
  color: #1a73e8;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const PlanPrice = styled.p`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
  text-align: left;

  li {
    margin-bottom: 0.5rem;
    color: #5f6368;

    &:before {
      content: '✓';
      color: #34a853;
      margin-right: 0.5rem;
    }
  }
`;

const PlanCTA = styled.a`
  display: inline-block;
  background-color: #1a73e8;
  color: white;
  padding: 0.8rem 1.5rem;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background-color: #1557b0;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const ComparisonTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 4rem;

  th, td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  th {
    background-color: #1a73e8;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f8f9fa;
  }
`;

function Pricing() {
    return (
      <PricingContainer>
        <Helmet>
          <title>
            Deeplink Pricing - Choose the Perfect Plan for Your Needs
          </title>
          <meta
            name="description"
            content="Explore Deeplink's flexible pricing plans. From free personal use to enterprise solutions, find the perfect package for your link shortening needs."
          />
        </Helmet>
        <Header>
          <Title>Simple, Transparent Pricing</Title>
          <Subtitle>
            Choose the plan that fits your needs. No hidden fees, no surprises.
          </Subtitle>
        </Header>
        <PricingTable>
          <PricingPlan
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <PlanName>Basic</PlanName>
            <PlanPrice>Free</PlanPrice>
            <PlanFeatures>
              <li>1,000 links/month</li>
              <li>Basic analytics</li>
              <li>Standard support</li>
              <li>Ad-supported</li>
            </PlanFeatures>
            <PlanCTA href={process.env.REACT_APP_FE_URL}>Get Started</PlanCTA>
          </PricingPlan>
          <PricingPlan
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            style={{
              transform: "scale(1.05)",
              boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <PlanName>Pro</PlanName>
            <PlanPrice>$29.99/month</PlanPrice>
            <PlanFeatures>
              <li>50,000 links/month</li>
              <li>Advanced analytics</li>
              <li>API access</li>
              <li>Priority support</li>
              <li>Ad-free experience</li>
            </PlanFeatures>
            <PlanCTA
              href={`${process.env.REACT_APP_FE_URL}?plan=pro`}
              style={{ backgroundColor: "#34a853" }}
            >
              Choose Pro
            </PlanCTA>
          </PricingPlan>
          <PricingPlan
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <PlanName>Enterprise</PlanName>
            <PlanPrice>Custom</PlanPrice>
            <PlanFeatures>
              <li>Unlimited links</li>
              <li>Custom features</li>
              <li>Dedicated account manager</li>
              <li>24/7 premium support</li>
              <li>SLA guarantees</li>
            </PlanFeatures>
            <PlanCTA href="https://Deeplink.com/contact">Contact Us</PlanCTA>
          </PricingPlan>
        </PricingTable>

        <ComparisonTable>
          <thead>
            <tr>
              <th>Feature</th>
              <th>Basic</th>
              <th>Pro</th>
              <th>Enterprise</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Links per month</td>
              <td>1,000</td>
              <td>50,000</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Analytics</td>
              <td>Basic</td>
              <td>Advanced</td>
              <td>Custom</td>
            </tr>
            <tr>
              <td>API Access</td>
              <td>-</td>
              <td>✓</td>
              <td>✓</td>
            </tr>
            <tr>
              <td>Custom Domain</td>
              <td>-</td>
              <td>✓</td>
              <td>✓</td>
            </tr>
            <tr>
              <td>Team Collaboration</td>
              <td>-</td>
              <td>Up to 5 users</td>
              <td>Unlimited</td>
            </tr>
          </tbody>
        </ComparisonTable>
      </PricingContainer>
    );
}

export default Pricing;