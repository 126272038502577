// Features.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const FeaturesContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 6rem 2rem;
  background: linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%);
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 6rem;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-weight: 800;
  letter-spacing: -0.5px;
`;

const Subtitle = styled.p`
  font-size: 1.4rem;
  color: #4a4a4a;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureItem = styled(motion.div)`
  display: flex;
  align-items: flex-start;
`;

const FeatureIcon = styled.div`
  width: 64px;
  height: 64px;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  font-size: 2rem;
  color: white;
  flex-shrink: 0;
`;

const FeatureContent = styled.div``;

const FeatureTitle = styled.h2`
  color: #1a1a1a;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
`;

const FeatureDescription = styled.p`
  color: #4a4a4a;
  line-height: 1.8;
  font-size: 1.1rem;
`;

const CtaSection = styled.div`
  margin-top: 6rem;
  text-align: center;
`;

const CtaButton = styled(motion.a)`
  display: inline-block;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
  padding: 1.2rem 3rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`;

const features = [
    {
        icon: "⚡️",
        title: "Lightning-Fast URL Shortening",
        description: "Generate short, powerful links in milliseconds. Our advanced algorithms ensure optimal performance, even at enterprise scale."
    },
    {
        icon: "🎨",
        title: "Brand-Centric Custom URLs",
        description: "Craft URLs that reinforce your brand identity. Our intuitive tools let you create memorable, on-brand links that resonate with your audience."
    },
    {
        icon: "📊",
        title: "Real-Time Analytics Dashboard",
        description: "Gain deep insights with our comprehensive analytics. Track engagement, analyze user behavior, and optimize your campaigns with data-driven decisions."
    },
    {
        icon: "🔌",
        title: "Seamless API Integration",
        description: "Integrate our powerful URL shortening capabilities into your workflow. Our robust API supports high-volume requests and complex use cases."
    },
    {
        icon: "🔒",
        title: "Enterprise-Grade Security",
        description: "Rest easy with our top-tier security measures. We employ end-to-end encryption, regular security audits, and comply with global data protection regulations."
    },
    {
        icon: "🌐",
        title: "Global CDN Infrastructure",
        description: "Ensure lightning-fast redirection worldwide. Our distributed network guarantees minimal latency, regardless of your users' locations."
    }
];

function Features() {
    return (
      <FeaturesContainer>
        <Helmet>
          <title>
            Deeplink Features - Enterprise-Grade URL Shortening and Management
          </title>
          <meta
            name="description"
            content="Discover Deeplink's cutting-edge features: lightning-fast shortening, custom branding, real-time analytics, seamless API integration, and more. Elevate your link management today."
          />
        </Helmet>
        <Header>
          <Title>Cutting-Edge Features for Modern Link Management</Title>
          <Subtitle>
            Experience the power of enterprise-grade URL shortening and
            analytics, tailored for businesses that demand excellence.
          </Subtitle>
        </Header>
        <FeatureGrid>
          {features.map((feature, index) => (
            <FeatureItem
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <FeatureIcon>{feature.icon}</FeatureIcon>
              <FeatureContent>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureContent>
            </FeatureItem>
          ))}
        </FeatureGrid>
        <CtaSection>
          <CtaButton
            href={process.env.REACT_APP_FE_URL}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            Schedule an Enterprise Demo
          </CtaButton>
        </CtaSection>
      </FeaturesContainer>
    );
}

export default Features;